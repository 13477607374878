<template>
  <v-row>
    <v-col cols="12" md="7" class="chartclass">
      <div class="example">
        <Bar
          :height="chartHeight"
          width="100"
          id="my-chart-id"
          :options="chartOptions"
          :data="modifiedChartData" 
          ref="chart"
        />
      </div>
    </v-col>
    <v-col cols="12" md="5">
      <ChartSession
        :data="dataValue"
        :datavisible="true"
        :allSessionData="allSessionValues"
        :key="graphPressed"
        :topTabSelected="topTabSelected"
        class="chartsessionclass" 
      ></ChartSession>
    </v-col>
  </v-row>
</template>


<script>
import ChartSession from "./ChartSessions.vue";
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartJSPluginDatalabels
);

var colors = ["#28C8C8"];


export default {
  name: "Chart",
  components: {
    Bar,
    ChartSession,
  },
  props: ["names", "eventTimes", "totalValues", "rawData", "topTabSelected"],
  data() {
    /// iterate this.eventTimes and for values more than 5, make the value 5
    let eventTimes = this.eventTimes.map((item) => {
      if (item > 5) {
        return 6;
      }
      return item;
    });
    return {
      graphPressed: false,
      availableActivties: this.names,
      dataValue: {},
      allSessionValues: [],
      selectedBarIndex: -1, // Track the index of the selected bar
      chartData: {
        labels: this.names,
        datasets: [
          {
            data: eventTimes,
            borderRadius: 5,
            backgroundColor: colors.slice(),
          },
        ],
      },
      chartOptions: {
        onClick: this.onClickHandler, // Use a separate method for the onClick event
        responsive: true,
        indexAxis: "y",
        maintainAspectRatio: false,
        scales: {
          x: {
            max: this.getxMax,
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
            display: false,
          },

          y: {
            border: {
              display: false,
            },
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          datalabels: {
            formatter: (val) => {
              // console.log("val is", val);
              if (val > 5) {
                return "> 5 mins";
              } 
              return val + " mins";
            },
            align: "end",
            anchor: "end",
            font: {
              size: 11,
              weight: 700,
            },
            offset: 0,
          },
          legend: {
            display: false,
          },
        },
        layout: {
          padding: {
            right: 50,
          },
        },
      },
    };
  },
  computed: {
    chartHeight() {
      if (this.chartData.labels.length === 1) {
        return 75;
      }
      return 75 + this.chartData.labels.length * 30;
    },
    getxMax() {
      const val = this.eventTimes[0];
      if (val > 99) {
        return val + 2;
      } else {
        return val + 1;
      }
    },
    getBgColor() {
      return "#28C8C8";
    },
    modifiedChartData() {
      return {
        ...this.chartData,
        datasets: this.chartData.datasets.map((dataset, datasetIndex) => {
          return {
            ...dataset,
            backgroundColor: dataset.data.map((_, index) => {
              if (index === this.selectedBarIndex) {
                return "#64007d"; // change the selected to purple
              } else {
                return colors[datasetIndex]; // Use the default color for other bars
              }
            }),
          };
        }),
      };
    },
  },
  methods: {
    onClickHandler(event, chartElements) {
      if (chartElements && chartElements.length > 0) {
        const clickedBarIndex = chartElements[0].index;
        if (clickedBarIndex === this.selectedBarIndex) {
          this.selectedBarIndex = -1; // Unselect the currently selected bar if clicked again
        } else {
          this.selectedBarIndex = clickedBarIndex;
          this.clickHandler(clickedBarIndex);
        }
      }
    },
    clickHandler(index) {
      // console.log("Event times is", this.eventTimes)
      this.graphPressed = true;
      this.dataValue = this.totalValues[index];
      if (typeof this.dataValue === "undefined") {
        return;
      }
      let ev = this.dataValue.event;
      this.allSessionValues = this.rawData.filter((item) => {
        return item.event === ev;
      });
    },
  },
};
</script>

<style></style>

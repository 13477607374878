<template>
  <VRow>
    <v-col cols="12" md="12" class="d-flex">
      <span class="nameheaderwhite">
        {{ userdata.name }} {{ userdata.surname }}
      </span>
      <span class="mt-1 generalwhitesmallfont emailfont">
        {{ userdata.email }}
      </span>
      <!-- <p>
        <span class="generalwhitesmallfont">Last Active : </span>
        <span class="generalwhitesmallfont"> 08-March-2023</span>
      </p> -->
    </v-col>
    <!-- <VCol cols="12" md="5">
      <span class="text-h6"> {{ userdata.name }} {{ userdata.surname }} </span>
    </VCol>
    <VCol cols="12" md="7">
      <span class="text-caption font-weight-thin">
        {{ userdata.email }}
      </span>
    </VCol> -->
  </VRow>
  <!-- <v-layout>
    <v-card height="100%" class="rounded-lg">
      <v-card-title primary-title>
        
      </v-card-title>
    </v-card>
  </v-layout> -->
</template>
<script>
export default {
  props: ["userdata"],
  data() {
    return {};
  },
  beforeMount() {},
};
</script>

<style scoped>
/* .profile {
  background-color: #d17f40;
  color: white;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
} */

.loginclass {
  font-weight: 600;
  font-size: 14px;
}
</style>

<template>
  <v-container class="">
    <VRow class="ma-2 pa-2" no-gutters :key="allUsers">
      <v-col cols="12" md="4" class="d-flex">
        <span class="namenormaltitle">
          {{ userDetails.name }} {{ userDetails.surname }}
        </span>
        <span class="emailfont mt-1 generalblacksmallfont">
          {{ userDetails.email }}
        </span>
      </v-col>
      <v-col cols="12" md="4" class="d-flex"> </v-col>
      <v-col cols="12" md="4" class="d-flex justify-center align-center">
        <AddUser
          :pname="userDetails.name"
          :psname="userDetails.surname"
          :pemail="userDetails.email"
        ></AddUser>
      </v-col>
    </VRow>
    <VRow class="ma-2 pa-2 generalblacksmallfont">
      <v-col cols="12" md="1"> SI No. </v-col>
      <v-col cols="12" md="3"> Name</v-col>
      <v-col cols="12" md="3"> Email </v-col>
      <v-col cols="12" md="3"> Status </v-col>
      <v-col cols="12" md="1"> Actions </v-col>
    </VRow>
    <VRow
      v-for="(val, index) in allUsers"
      :key="index"
      v-bind:class="classCheckForElevation(val.status)"
    >
      <v-col cols="12" md="1" class="generalblacksmallfont">
        {{ index + 1 }}
      </v-col>
      <v-col cols="12" md="3" class="nameheader">
        {{ val.name }} {{ val.surname }}</v-col
      >
      <v-col cols="12" md="3" class="generalblacksmallfont"> {{ val.email }} </v-col>
      <v-col cols="12" md="3">
        <span v-bind:class="getClassName(val.status)">
          {{ val.status }}
        </span>
        &nbsp;
        <span class="text-caption daysago">{{ showDays(val) }}</span>
      </v-col>
      <v-col cols="12" md="1">
        <DeleteUser :user="val"></DeleteUser>
      </v-col>
      <v-col cols="12" md="1">
        
        <VBtn
          variant="text"
          height="100%"
          class="text-decoration-underline actionsbutton no-uppercase"
          @click="btnPressed(val)"
          >{{ classCheckForViewButton(val.status) }}</VBtn
        >
      </v-col>
    </VRow>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import router from "../../router";
import AddUser from "./AddUser.vue";
import DeleteUser from "./DeleteUser.vue";
import closebutton from "@/assets/UI/xmark.png";

export default defineComponent({
  name: "App",
  data() {
    return {
      userDetails: {},
      allUsers: [],
      closebutton: closebutton,
    };
  },
  beforeMount() {
    this.getUsers();
  },
  mounted() {
    this.emitter.on("useradded", (isOpen) => {
      this.getUsers();
    });
  },
  computed: {},
  components: {
    AddUser,
    DeleteUser
  },
  updated() {},
  methods: {
    getUsers() {
      const pid = localStorage.getItem(this.LOGIN_UID)//"op2qb18E3yghhoT7iRAdyMrJsgv1";
      // console.log("---->get users2", pid);
      axios
        .get(this.BASE_URL + "userlist?pid=" + pid)
        .then((res) => {
          const data = res.data;
          this.userDetails = data.userprofile;
          localStorage.setItem(
            "currentparent",
            JSON.stringify(data.userprofile)
          );
          this.allUsers = data.studentdetails;
        })
        .catch((error) => {
          console.log("error", error);
          return [];
        });
    },
    getClassName(status) {
      if (status === "Approved") {
        return "approved";
      } else if (status === "Pending") {
        return "pending";
      } else {
        return "cancelled";
      }
    },
    classCheckForElevation(status) {
      if (status === "Approved") {
        return "ma-2 pa-2 mb-4 rounded-lg approvedclass";
      } else {
        return "ma-2 pa-2 mb-4 rounded-lg pendingclass";
      }
    },
    classCheckForViewButton(status) {
      if (status === "Approved") {
        return "View";
      } else {
        return "Resend";
      }
    },
    showDays(value) {
      if (value.status === "Approved") {
        return "";
      } else if (value.status === "Pending") {
        return "sent " + this.relativeDays(value.time);
      } else {
        return "added " + this.relativeDays(value.time);
      }
    },
    relativeDays(timestamp) {
      const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });
      const aDay = 1000 * 60 * 60 * 24;
      const diffInDays = Math.round((timestamp - Date.now()) / aDay);
      return rtf.format(diffInDays, "day");
    },
    btnPressed(value) {
      if (value.status === "Approved") {
        this.viewPressed(value);
      } else {
        const pid = localStorage.getItem(this.LOGIN_UID) //"op2qb18E3yghhoT7iRAdyMrJsgv1";
        this.resendPressed(
          pid,
          this.userDetails.name,
          this.userDetails.surname,
          value.email,
          value.cid
        );
      }
    },
    viewPressed(val) {
      let user = val;
      localStorage.setItem("currentstudent", JSON.stringify(user));
      this.$store.dispatch("setcurrentstudent", JSON.stringify(user));
      router.push({
        name: "Dashboard",
      });
    },
    resendPressed(pid, pname, psname, cemail, cid) {
      axios
        .get(
          this.BASE_URL +
            "resendverifyemail?pid=" +
            pid +
            "&pname=" +
            pname +
            "&psname=" +
            psname +
            "&cemail=" +
            cemail +
            "&cid=" +
            cid
        )
        .then((res) => {
          this.getUsers();
        })
        .catch((error) => {
          console.log("error", error);
          return [];
        });
    },
    deletePressed(index) {
      this.$confirm({
        title: "Remove User tracking",
        message:
          "You will no longer be able to view their activties and progress.",
        button: {
          no: "Cancel",
          yes: "Delete",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            // ... do something
            let user = this.allUsers[index];
            const pid = user.pid;
            const cid = user.cid;
            axios
              .get(this.BASE_URL + "deleteuser?pid=" + pid + "&cid=" + cid)
              .then((res) => {
                this.getUsers();
              })
              .catch((error) => {
                console.log("error", error);
                return [];
              });
          }
        },
      });
    },
  },
});
</script>

<style>
.actionsbutton {
  color: #64007d;
}
.approved {
  color: #66bb59;
  font-weight: 600;
}
.pending {
  color: #0494e4;
  font-weight: 600;
}
.cancelled {
  color: #f45151;
  font-weight: 600;
}
.daysago {
  color: #515151;
}
.approvedclass {
  border-width: 1px;
  border-color: #d9d9d9;
}
.pendingclass {
  background-color: #f9f9f9;
}
.no-uppercase {
  text-transform: unset !important;
}
</style>

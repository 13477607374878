<template>
  <VRow>
    <VCol> </VCol>
  </VRow>
</template>
<script>
import { defineComponent } from "vue";
import router from "../../router";

export default defineComponent({
  data() {
    return {
      datachecking: true,
    };
  },
  mounted() {
    this.checkforlogin();
    this.emitter.emit("nav-bar-change", "home");
  },
  activated() {
    this.checkforlogin();
  },
  methods: {
    checkforlogin() {
      // console.log("checkforlogin");
      //   let item = localStorage.getItem(this.LOGIN_ACCESSTOKEN);
      let item2 = localStorage.getItem(this.LOGIN_UID) ?? "";
      console.log("item2", item2);
      if (typeof item2 === "undefined" || item2.length === 0) {
        //Login push
        router.push({
          name: "Login",
        });
      } else {
        this.checkforentitlement();
      }
    },
    checkforentitlement() {
      const val = localStorage.getItem(this.CHECK_ENTITLEMENT) ?? "";
      if (val === "") {
        //No entitlement
        router.push({
          name: "Access",
        });
      } else {
        
        const item3 = JSON.parse(val)
        if (typeof item3 === "undefined" || item3.length === 0 || Object.keys(item3).length === 0) {
          //No entitlement
          router.push({
            name: "Access",
          });
        } else {
          // Show user dashboard
          router.push({
            name: "Home",
          });
        }
      }
    },
  },
});
</script>

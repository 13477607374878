<template>
  <v-row class="d-flex justify-end">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ props }">
          <VBtn variant="text" class="btnlearner" v-bind="props"
            ><span style="font-weight: 700">+</span>Add Learner
          </VBtn>
        </template>
        <v-card>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <span class="text-h6 nameheader">Add Learner</span>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="email"
                    label="Student Email Address*"
                    required
                    :rules="emailRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" v-if="this.validInDb == false">
                  <small style="color: red">{{ this.errorMsg }}</small>
                </v-col>
                <v-col cols="12" md="12">
                  <small
                    >*An email requesting approval will be sent to the user. You
                    can view their session details once they approve.</small
                  >
                </v-col>
                <v-col cols="12" md="12">
                  <v-card-actions class="d-flex justify-center">
                    <!-- <div class="mt-4 pa-4">
                      <v-btn elevation="2" width="200" @click="dialog = false"
                        >Close</v-btn
                      >
                    </div> -->
                    <div class="mt-4 pa-4">
                      <button
                        :disabled="!valid"
                        v-bind:class="classCheck"
                        class="primarybackground generalwhitemediumfont btnwidth"
                        @click="validateEmailInDb"
                      >
                        Submit
                      </button>
                    </div>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-form>
  </v-row>

  <!-- <div class="text-center">
    <v-dialog v-model="lastdialog">
      <v-card>
        <v-card-text>
          An approval request has been sent to the user.
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" block @click="lastdialog = false">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div> -->
</template>

<script>
import axios from "axios";

// const pid = localStorage.getItem(this.LOGIN_UID)//"op2qb18E3yghhoT7iRAdyMrJsgv1";

export default {
  props: ["pname", "psname", "tpemailotalValues"],
  data: () => ({
    dialog: false,
    valid: true,
    validInDb: true,
    errorMsg: "Email does not exists",
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
  }),
  computed:{
    classCheck(){
      if(this.valid){
        return "primarybackground generalwhitemediumfont btnwidth"
      }else{
        return "primarybackgrounddisabled generalblackmediumfont btnwidth"
      }
    }
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    validateEmailInDb() {
      if (!this.valid) {
        console.log("Invalid");
        return;
      }
      const pid = localStorage.getItem(this.LOGIN_UID);
      let url =
        this.BASE_URL +
        "validateemail?email=" +
        this.email +
        "&pid=" +
        pid +
        "&pname=" +
        this.pname +
        "&psname=" +
        this.psname;
      axios
        .get(url)
        .then((res) => {
          let success = res.data.success;
          if (res.data.success == false) {
            this.validInDb = false;
            this.errorMsg = res.data.error.message;
          } else {
            // User Will be added and response will be sent. Show dialog with user added
            this.validInDb = true;
            this.dialog = false;
            this.emitter.emit("useradded", "useradded");
          }
        })
        .catch((error) => {
          this.validInDb = false;
          this.errorMsg = "Email not found in the database";
          console.log("error", error);
          return [];
        });
    },
  },
};
</script>

<style>
.btnlearner {
  color: #d17f40;
  border-width: 1px;
  border-color: #d17f40;
}
.btnwidth {
  width: 400px;
  height: 47px;
  border-radius: 5px;
}
</style>

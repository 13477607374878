<template>
  <div class="ml-4">
    <div v-for="(val, ind) in dropdownvalues" :key="ind">
      <div class="d-flex align-center">
        <span
          :style="getcosmoidcolour(ind)"
          :class="labelClass()"
          >{{ ind + 1 }}</span
        >
        <span class="cosmoidvalueclass ma-2"> {{ val }} sec</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["dropdownvalues","showCosmoidLayout", "colourOrder"],
  components: {
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    getcosmoidcolour(index) {
      console.log("Colour is",this.colourOrder)
      return "background-color: " + this.returnColourHex(this.colourOrder[index]);
    },
    returnColourHex(color){
      if(color == "blue"){
        return "#3172DD"
      }else if(color == "green"){
        return "#02E140"
      }else if(color == "yellow"){
        return "#FCEA2D"
      }else if(color == "orange"){
        return "#F19846"
      }else if(color == "red"){
        return "#F9502C"
      }else if(color == "purple"){
        return "#D669FC"
      }else if(color == "pink"){
        return "#FFADCB"
      }else if(color == "fuschia"){
        return "#F12CBA"
      }else if(color == "turquoise"){
        return "#07D2FF"
      }else if(color == "light green"){
        return "#23FCE2"
      }else if(color == "fluroscent green"){
        return "#94FB10"
      }else if(color == "dark yellow"){
        return "#E4ED79"
      }else if(color == "light purpler" || color == "light purple"){
        return "#D6CBF9"
      }else{
        return "#000000"
      }
    },
    labelClass(){
      if(this.showCosmoidLayout){
        return "cosmoidcircleclass ma-2 d-flex align-center justify-center"
      }else{
        return "cosmoidcircleclass ma-2 d-flex align-center justify-center"
      }
    }
  },
};
</script>
<style>
.cosmoidcircleclass {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  font-size: 8pt;
  border-width: 1px;
  border-color: #515151;
  color: white;
}

.cosmoidvalueclass {
  height: 25px;
  display: inline-block;
  text-align: center;
  font-size: 12pt;
  color: #515151;
}
</style>

<template>
  <div>
    <div v-if="isback === false && isfront === false" :class="getclassname">
    {{ geta() }}
  </div>
  <div
    v-if="isback === true"
    class="getcircleclass d-flex justify-center align-center"
  >
    <VIcon icon="mdi-menu-left"  :color="buttoncolorprevious" />
  </div>
  <div
    v-if="isfront === true"
    class="getcircleclass d-flex justify-center align-center"
  >
    <VIcon icon="mdi-menu-right" :color="buttoncolorforward" />
  </div>
  </div>
  
  <!-- <VRow>
    <VCol
      cols="12"
      md="12"
      class="containerclass d-flex justify-center align-center"
    >
      
    </VCol>
  </VRow> -->
</template>

<script>
export default {
  components: {},
  computed: {
    getclassname: function () {
      if (this.needsborder) {
        return "getcircleclasswithselection primarybackground d-flex justify-center align-center";
      } else {
        return "getcircleclass d-flex justify-center align-center";
      }
    },
    buttoncolorprevious: function () {
      if (this.previousavailable) {
        return "#64007d";
      } else {
        return "grey";
      }
    },
    buttoncolorforward: function () {
      if (this.forwardavailable) {
        return "#64007d";
      } else {
        return "grey";
      }
    },
  },
  props: [
    "sessionValue",
    "isback",
    "isfront",
    "needsborder",
    "previousavailable",
    "forwardavailable",
  ],
  methods: {
    geta() {
      return this.sessionValue;
    },
  },
};
</script>
<style scoped>

.getcircleclass {
  height: 30px;
  width: 30px;
  border-width: 1px;
  border-color: #c4c4c4;
  border-radius: 15px;
  text-align: center;
  font-size: 9pt;
}

.getcircleclasswithselection {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  text-align: center;
  font-size: 9pt;
  color: white;
}
</style>

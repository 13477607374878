import { createApp } from "vue";
import App from "./App.vue";
import vtfy from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from "./router";
import VueTableLite from "vue3-table-lite";
import { createStore } from "vuex";
import DatePicker from "@rameshjs/datepicker-vue";
import "@rameshjs/datepicker-vue/dist/style.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import { createVuetify } from "vuetify";
import "vuetify/dist/vuetify.min.css";
import { aliases, md } from "vuetify/iconsets/md";
import "mdb-vue-ui-kit/css/mdb.min.css";
import mitt from 'mitt';
import Vue3ConfirmDialog from 'vue3-confirm-dialog';
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import "@fontsource/noto-sans/200.css";
import "@fontsource/noto-sans/300.css";
import "@fontsource/noto-sans/400.css";
import "@fontsource/noto-sans/500.css";
import "@fontsource/noto-sans/600.css";
import "@fontsource/noto-sans/700.css";
import "@/assets/global.css";

export default createVuetify({
  icons: {
    defaultSet: "md",
    aliases,
    sets: {
      md,
    },
  },
  components,
  directives,
});

loadFonts();

// const store = createStore({
//   state() {
//     return {
//       student: {},
//       cid: 0,
//     };
//   },
//   mutations: {
//     increment(state) {
//       state.cid++;
//     },
//   },
// });

const emitter = mitt();

const store = createStore({
  state: {
    student: {},
    user: {
      loggedIn: false,
      data: null,
    },
  },
  actions: {},
  mutations: {
    setcurrentstudent(state, st) {
      state.student = st;
    },
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      state.user.data = data;
    },
  },
  actions: {
    setcurrentstudent(state, st) {
      state.commit("setcurrentstudent", st);
    },
    async register(context, { email, password, name }) {
      const response = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (response) {
        context.commit("SET_USER", response.user);
        response.user.updateProfile({ displayName: name });
      } else {
        throw new Error("Unable to register user");
      }
    },
    async logIn(context, { email, password }) {
    },

    async logOut(context) {
      await signOut(auth);
      context.commit("SET_USER", null);
    },

    async fetchUser(context, user) {
      context.commit("SET_LOGGED_IN", user !== null);
      if (user) {
        context.commit("SET_USER", {
          displayName: user.displayName,
          email: user.email,
        });
      } else {
        context.commit("SET_USER", null);
      }
    },
  },
  getters: {
    getcurrentstudent: (state) => state.student,
    user(state) {
      return state.user;
    },
  },
  modules: {},
});
const app = createApp(App);

var myglobalvariables = {
  data() {
    return {
      BASE_URL: "https://europe-west2-cosmo-8b9d0.cloudfunctions.net/",
      TEST_URL:
        "https://europe-west2-cosmo-8b9d0.cloudfunctions.net/",
      LOGIN_ACCESSTOKEN:"accesstoken",
      LOGIN_UID:"uid",
      CHECK_ENTITLEMENT:"entitlement",
      sessionrowgrid:7
    };
  },
};

app.config.globalProperties.emitter = emitter;

app
  .use(vtfy)
  .use(router)
  .use(VueTableLite)
  .use(store)
  .use(Vue3ConfirmDialog)
  .component("DatePicker", DatePicker)
  .mixin(myglobalvariables)
  .mount("#dashboard-app");

<template>
  <div class="text-center">
    <VBtn
      variant="text"
      height="100%"
      class="text-decoration-underline no-uppercase"
    >
      Delete

      <v-dialog v-model="dialog" activator="parent" width="500" height="300">
        <VRow class="dialogclass">
          <VCol cols="12" md="12" class="d-flex justify-center align-center">
            <div class="generalblackfont fontweightsemibold">
              Do you want to delete the below learner ?
            </div>
          </VCol>
          <VCol cols="12" md="12" class="d-flex justify-center align-center">
            <div class="nameclass">
              <div class="generalprimarylargefont textalligncenter">
                {{ user.name }} {{ user.surname }}
              </div>
              <br />
              <div class="textalligncenter generalblackfont">
                added on {{ getDateAddedOn }}
              </div>
            </div>
          </VCol>
          <VCol cols="12" md="12" class="d-flex justify-center align-center">
            <VBtn @click="deletePressed" class="deletebtnclass btnafterauth"
              >Delete Learner</VBtn
            >
          </VCol>
          <VCol cols="12" md="12" class="d-flex justify-center align-center">
            <VBtn
              variant="text"
              height="100%"
              class="text-decoration-underline no-uppercase"
              @click="cancelPressed"
              >Cancel</VBtn
            >
          </VCol>
        </VRow>
      </v-dialog>
    </VBtn>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["user"],
  data: () => ({
    dialog: false,
    valid: true,
    validInDb: true,
    errorMsg: "Email does not exists",
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
  }),
  computed: {
    getDateAddedOn() {
      return this.convertDate(this.user.time);
    },
  },
  methods: {
    deletePressed() {
      let user = this.user;
      const pid = user.pid;
      const cid = user.cid;
      console.log("delete pressed", pid, cid);
      axios
        .get(this.BASE_URL + "deletAnalyticsUser?pid=" + pid + "&cid=" + cid)
        .then((res) => {
          this.emitter.emit("useradded", "useradded");
            this.dialog = false;
        })
        .catch((error) => {
          console.log("error", error);
          return [];
        });
    },
    convertDate(date) {
      let convertedDate = new Date(date * 1000);
      return convertedDate
        .toLocaleDateString("pt-br")
        .split("/")
        .reverse()
        .join("-");
    },
    cancelPressed() {
      this.dialog = false;
    },
  },
};
</script>

<style>
.deletebtnclass {
  background-color: #d17f40;
  width: 75%;
  height: 45px;
  color: white;
  text-align: center;
  border-radius: 5px;
}
.nameclass {
  width: 75%;
  height: 125px;
  border-width: 0.5px;
  border-color: #d9d9d9;
  vertical-align: middle;
  border-radius: 5px;
}

.dialogclass {
  border-radius: 5px;
  background-color: white;
}
</style>

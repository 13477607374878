<template>
    <div id="home">
        <h1>Verification Successful!</h1>
        <p>Congratulations! Your account has been successfully verified.<br>
        <button @click="goToHomePage">Go to Home</button></p>
    </div>
</template>

<script>
export default {
    methods: {
        goToHomePage() {
            this.$router.push('/');
        }
    }
}
</script>

<style scoped>
/* Add your custom styles here */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

button {
    padding: 10px 20px;
    background-color: #64017C;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px; /* Add some space above the button */
}

h1, p, button {
    text-align: center;
}

h1, p {
    margin: 0; /* Remove default margins */
}

#home {
    padding: 20px;
}   
</style>
<template>
  <VRow class="mt-4">
    <VCol cols="12" md="4"></VCol>
    <VCol
      cols="12"
      md="4"
      class="ma-4 greybackground containerclass"
      :key="passwordsent"
    >
      <div v-if="passwordsent === false">
        <div class="ma-6 namenormaltitle primarycolor fontweightsemibold">
          Forgot Password?
        </div>
        <div>
          <div v-if="error" class="alert alert-danger">{{ error }}</div>
          <form action="#" @submit.prevent="login">
            <div for="email" class="generalblackfont ml-6">
              Enter yout email address below. <br />We'll send a password reset
              link to your inbox
            </div>
            <div class="mt-4 mb-4 ml-6 col-md-10">
              <input
                id="email"
                type="email"
                class="form-control"
                name="email"
                value
                required
                autofocus
                v-model="email"
              />
            </div>

            <br />

            <div class="mt-4 ml-8 col-md-10 ">
              <button
                @click="forgotpasswordclicked"
                width="100%"
                type="submit"
                class="primarybackground generalwhitemediumfont btnauthclass"
              >
                Send Link
              </button>
            </div>

            <div class="mt-4 ml-8 col-md-10 elevation-2">
              <button
                width="100%"
                @click="loginclick"
                type="submit"
                class="bordercolorprimary generalprimarymediumfont btnauthclass"
              >
                Back to login
              </button>
            </div>
          </form>
        </div>
      </div>
      <div v-if="passwordsent && passwordresent === false">
        <div class="ma-10">
          <img class="linksentclass" :src="linksent" />
        </div>
        <div class="ma-10 generalblackfont">
          A password reset link has been sent to your below email address
          {{ email }}
        </div>
        <br />
        <div class="ml-10 mt-4 generalblackfont">
          Have you still not received the link?
          <v-btn
            class="generalprimaryfont text-decoration-underline no-uppercase mb-1"
            style="font-weight: 400"
            @click="forgotpasswordclicked"
            variant="text"
            >Resend</v-btn
          >
        </div>
        <div class="mt-4 ml-10 col-md-10 elevation-2">
          <button
            width="100%"
            @click="loginclick"
            type="submit"
            class="bordercolorprimary generalprimarymediumfont btnauthclass"
          >
            Back to login
          </button>
        </div>
      </div>
      <div v-if="passwordsent && passwordresent">
        <div class="ma-10">
          <img class="linksentclass" :src="linkresent" />
        </div>
        <div class="ma-10 generalblackfont">
          A password reset link has been sent to your below email address
          {{ email }}
        </div>
        <br />
        <div class="ml-10 mt-4 generalblackfont">
          Still having trouble?
          <v-btn
            class="generalprimaryfont text-decoration-underline no-uppercase mb-1"
            style="font-weight: 400"
            @click="contactusclicked"
            variant="text"
            >Contact us</v-btn
          >
        </div>
        <div class="mt-4 ml-10 col-md-10 elevation-2">
          <button
            width="100%"
            @click="loginclick"
            type="submit"
            class="bordercolorprimary generalprimarymediumfont btnauthclass"
          >
            Back to login
          </button>
        </div>
      </div>
    </VCol>
    <VCol cols="12" md="4"> </VCol>
  </VRow>
</template>

<script>
import { ref, defineComponent } from "vue";
import firebase from "../firebaseconfig";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import router from "../../router";
import closebutton from "@/assets/UI/xmark.png";
import linksent from "@/assets/UI/linksent.png";
import linkresent from "@/assets/UI/linkresent.png";

export default defineComponent({
  data() {
    return {
      email: ref(""),
      error: ref(null),
      snackbar: false,
      closebuttonshow: false,
      closebutton: closebutton,
      passwordsent: false,
      passwordresent: false,
      linksent: linksent,
      linkresent: linkresent,
    };
  },
  mounted() {},
  methods: {
    forgotpasswordclicked() {
      sendPasswordResetEmail(getAuth(), this.email).then(
        () => {
          if (this.passwordsent === true) {
            this.passwordresent = true;
          }
          this.passwordsent = true;
        },
        (err) => {
          console.log(err);
          this.error = err;
        }
      );
    },
    loginclick() {
      router.push({
        name: "Login",
      });
    },
    contactusclicked() {},
  },
});
</script>

<style>
.closebutton {
  align-items: end;
}
.containerclass {
  height: 450px;
  border-radius: 5px;
}
.linksentclass {
  height: 45px;
  /* width: 120px; */
}
</style>

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBMy9pWk89wOJvBRzjw6jYOnS_knWAXJJo",
  authDomain: "cosmo-8b9d0.firebaseapp.com",
  databaseURL: "https://cosmo-8b9d0.firebaseio.com",
  projectId: "cosmo-8b9d0",
  storageBucket: "cosmo-8b9d0.appspot.com",
  messagingSenderId: "810219655084",
  appId: "1:810219655084:web:09c513f28865cf93807409",
  measurementId: "G-XFQXJ6W6F0"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
export default firebase;
<template>
  <VRow class="mt-4">
    <VCol cols="12" md="4"></VCol>
    <VCol cols="12" md="4" class="ma-4 greybackground containerclass">
      <div>
        <div class="ma-8">
          <img class="noaccessclass" :src="noaccess" />
        </div>
        <div>
          <div class="generalblacksmallfont ml-8">
            It appears that you do not have access to this page.<br/> If you believe
            this is an error or require access.
          </div>
          <div class="ml-4 mt-6">
            <v-btn
              class="generalprimarysmallfont text-decoration-underline no-uppercase"
              @click="contactUsPressed"
              variant="text"
              >Contact us</v-btn
            >
          </div>
        </div>
      </div>
    </VCol>
    <VCol cols="12" md="4"> </VCol>
  </VRow>
</template>

<script>
import noaccess from "@/assets/UI/noaccess.png";
export default {
  name: "Entitlement",
  components: {},
  data: {
    noaccess:noaccess
  },
  mounted() {
    this.emitter.emit("nav-bar-change", "home");
  },
  methods: {
    contactUsPressed() {},
  },
};
</script>
<style>

.noaccessclass {
  height: 60px;
  /* width: 120px; */
}

.containerclass{
  height:300px;
}

</style>

<template>
  <nav id="vue">
    <v-row>
      <v-col cols="12" md="2">
        <div @click="logoClicked" class="menu-item">
          <img width="100" height="100" class="thumbnailInfo" :src="logo" />
          <router-link to="/"></router-link>
        </div>
      </v-col>
      <v-col cols="12" md="7"></v-col>
      <v-col cols="12" md="1">
        <div v-if="homeshow" class="menu-item">
          <router-link to="/home">Home</router-link>
        </div>
      </v-col>
      <v-col cols="12" md="2">
        <div @click="logoutclicked" v-if="logoutshow" class="menu-item">
          <img width="100" height="100" class="thumbnailInfo" :src="logout" />
        </div>
      </v-col>
    </v-row>
  </nav>
</template>

<script>
import logout from "@/assets/logout.png";
import logo from "@/assets/logo.png";
import router from "../../router";

export default {
  name: "NavBar",
  components: {},
  data: {
    logout: logout,
    logo: logo,
    homeshow: false,
    logoutshow: false,
  },
  mounted() {
    this.emitter.on("nav-bar-change", (isOpen) => {
      this.homeshow = this.checkforentitlement();
      this.logoutshow = this.checkforloggedin();
    });
  },
  created() {},
  computed: {},
  methods: {
    logoutclicked() {
      localStorage.removeItem(this.LOGIN_ACCESSTOKEN);
      localStorage.removeItem(this.LOGIN_UID);
      localStorage.removeItem(this.CHECK_ENTITLEMENT);
      router.push({
        name: "Initial",
      });
    },
    checkforentitlement() {
      const val = localStorage.getItem(this.CHECK_ENTITLEMENT) ?? {};
      if (this.checkforValidJson(val)) {
        const item3 = JSON.parse(val);
        if (
          typeof item3 === "undefined" ||
          item3.length === 0 ||
          Object.keys(item3).length === 0
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    checkforloggedin() {
      const val = localStorage.getItem(this.LOGIN_UID) ?? "";
      if (typeof val === "undefined" || val.length === 0) {
        return false;
      } else {
        return true;
      }
    },
    logoClicked() {
      router.push({
        name: "Initial",
      });
    },
    checkforValidJson(str) {
      try {
        return JSON.parse(str) && !!str;
      } catch (e) {
        return false;
      }
    },
  },
};
</script>
<style>
nav {
  width: 100vw;
  background-color: #64007d;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}
nav .menu-item {
  color: #fff;
  padding: 25px 40px;
  position: relative;
  text-align: center;
  border-bottom: 5px solid transparent;
  display: flex;
  transition: 0.4s;
}
nav .menu-item.active,
nav .menu-item:hover {
  background-color: #64007d;
  color: #f77907;
}
nav .menu-item a {
  color: inherit;
  text-decoration: none;
}
</style>

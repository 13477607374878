<template>
  <VRow>
    <VCol cols="12" md="3"></VCol>
    <VCol cols="12" md="6" class="ma-4">
      <div class="card">
        <div class="card-header">Signup</div>
        <div class="card-body">
          <div v-if="error" class="alert alert-danger">{{ error }}</div>
          <form action="#" @submit.prevent="login">
            <div class="form-group row">
              <label for="email" class="col-md-4 col-form-label text-md-right"
                >Email</label
              >
              <div class="col-md-6">
                <input
                  id="email"
                  type="email"
                  class="form-control"
                  name="email"
                  value
                  required
                  autofocus
                  v-model="email"
                />
              </div>
            </div>

            <div class="form-group row">
              <label
                for="password"
                class="col-md-4 col-form-label text-md-right"
                >Password</label
              >

              <div class="col-md-6">
                <input
                  id="password"
                  type="password"
                  class="form-control"
                  name="password"
                  required
                  v-model="password"
                />
              </div>
            </div>

            <div class="form-group row">
              <label
                for="password"
                class="col-md-4 col-form-label text-md-right"
                >Confirm Password</label
              >

              <div class="col-md-6">
                <input
                  id="password"
                  type="password"
                  class="form-control"
                  name="cpassword"
                  required
                  v-model="cpassword"
                />
              </div>
            </div>

            <div class="form-group row">
              <label
                for="organisation"
                class="col-md-4 col-form-label text-md-right"
                >Organisation</label
              >

              <div class="col-md-6">
                <input
                  class="form-control"
                  name="organisation"
                  required
                  v-model="cpassword"
                />
              </div>
            </div>

            <div class="form-group row mb-0 mt-2">
              <div class="col-md-8 offset-md-4">
                <button type="submit" class="btn btn-primary">Signup</button>
              </div>
            </div>
            <div class="form-group row mb-0 mt-2">
              <div class="col-md-8 offset-md-4">
                <v-btn @click="loginclicked" variant="text"
                  >Already have an account? Signin here</v-btn
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </VCol>
    <VCol cols="12" md="3"> </VCol>
  </VRow>
</template>

<script>
import { ref, defineComponent } from "vue";
import firebase from "../firebaseconfig";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import axios from "axios";
import router from "../../router";

export default defineComponent({
  data() {
    return {
      email: ref(""),
      password: ref(""),
      cpassword: ref(""),
      uid: "",
      accessToken: "",
      error: ref(null),
    };
  },
  mounted() {
    this.$root.$emit("mySpecialName");
  },
  methods: {
    loginclicked() {
      router.push({
        name: "Login",
      });
    },
  },
});
</script>

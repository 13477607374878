<template>
  <v-app id="inspire">
    <v-main class="grey lighten-3">
      <v-container>
        <UserTable />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import UserTable from "./DataTable.vue";

export default {
  components: {
    UserTable
  },
  created() {

  },
  mounted(){
    this.emitter.emit("nav-bar-change", "home");
  }
};
</script>

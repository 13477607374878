<template>
  <v-app id="inspire" :key="totalValues">
    <v-main class="grey lighten-3" :key="tabSelected">
      <v-container class="">
        <VRow class="mb-2 orangebackground borderwidththin">
          <VCol cols="12" md="8" class="d-flex justify-center align-center">
            <Profile :userdata="userdata" />
          </VCol>
          <VCol cols="12" md="1" class="d-flex justify-center align-center">
          </VCol>
          <VCol cols="12" md="3" class="d-flex justify-end align-center">
            <span class="generalwhitesmallfont">Last Online</span>
            <div>
              <img class="infoicontopclass" :src="infoicontop" />
              <v-tooltip activator="parent" location="bottom" height="80" width="500" color="black">The "Last Online"
                refers to the most recent date and time when
                a learner engaged in activities online, and doesn't include any
                offline activities they may have done since those aren't
                tracked.</v-tooltip>
            </div>

            <span class="mx-1 nameheaderwhitesmallbold"> {{ lastlogin }}</span>
          </VCol>
        </VRow>
        <VRow>
          <VCol cols="12" md="12">
            <VRow class="greybackground bordercurve">
              <VCol cols="12" md="2" class="d-flex justify-left align-center">
                <MDBDropdown variant="none" class="bordercolorprimary borderwidththin dropdownclass" v-model="dropdown1">
                  <MDBDropdownToggle @click="dropdown1 = !dropdown1" color="white" class="dropdowntoggleclass pl-3"
                    auto-close style="
                      height: 38px;
                      color: #64007d;
                      text-align: left;
                      vertical-align: super;
                      font-weight: 700;
                      font-size: 16px;
                      display: flex;
                      align-items: center;
                    " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{ tabDefault }}
                    <!-- <button @click="monthClicked"></button> -->
                  </MDBDropdownToggle>
                  <MDBDropdownMenu aria-labelledby="dropdownMenuButton">
                    <MDBDropdownItem @click="dayClicked" href="#">Day</MDBDropdownItem>
                    <MDBDropdownItem @click="monthClicked" href="#">Month</MDBDropdownItem>
                    <MDBDropdownItem @click="yearClicked" href="#">Year</MDBDropdownItem>
                    <MDBDropdownItem @click="customClicked" href="#">Custom</MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </VCol>
              <VCol v-if="tabSelected === 0" cols="12" md="2" class="d-flex justify-left align-center">
                <VueDatePicker mode-height="35px" format="dd MMM yyyy"
                  class="bordercolorprimary borderwidththin dropdownclass" v-model="dateForPicker"
                  :enableTimePicker="false" :max-date="datePickerUpperLimit" :min-date="datePickerLowerLimit" auto-apply
                  hide-input-icon style="
                    color: #64007d;
                    text-align: left;
                    vertical-align: super;
                    font-weight: 700;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                  ">
                  <template #input-icon> </template>
                  <template #clear-icon>
                    <img class="ma-2 thumbnailInfo" width="20" src="https://img.icons8.com/ios/512/calendar--v4.png" />
                  </template>
                </VueDatePicker>
              </VCol>

              <!-- <VCol v-if="tabSelected===0" cols="12" md="1"></VCol> -->
              <VCol cols="12" md="1" class="d-flex justify-center align-center greybackground" v-if="tabSelected != 3">
                <v-btn size="35" class="mr-1" icon="mdi-star" @click="backArrowClicked">
                  <VIcon size="35" icon="mdi-menu-left" color="#64007d" />
                </v-btn>
              </VCol>
              <VCol cols="12" :md="getColumns" v-for="(val, index) in getDates" :key="index"
                class="d-flex justify-center align-center">
                <DateCard :dateValue="val" :data="getValueForDate(val)" :tabSelected="tabSelected"
                  :needBorder="needBorder(index)" @click="dateClicked(val, index)" :datadisabled="dateDisabled(val)"
                  class="datecardclass">
                </DateCard>
              </VCol>
              <VCol cols="12" md="1" class="d-flex justify-center align-center greybackground" v-if="tabSelected === 3">
              </VCol>
              <VCol cols="12" md="6" class="d-flex justify-center align-center greybackground" v-if="tabSelected === 3">
                <VueDatePicker mode-height="35px" format="dd MMM yyyy" auto-apply
                  class="bordercolorprimary borderwidththin dropdodaterangeclasswnclass" :enableTimePicker="false" range
                  :partial-range="false" :max-date="datePickerUpperLimit" :min-date="datePickerLowerLimit"
                  v-model="daterange" hide-input-icon placeholder="Select a date range"
                  style="color: #64007d; font-weight: 700; font-size: 16px">
                  <template #input-icon> </template>
                  <template #clear-icon>
                    <img class="ma-2 thumbnailInfo" width="20" src="https://img.icons8.com/ios/512/calendar--v4.png" />
                  </template>
                </VueDatePicker>
              </VCol>
              <VCol cols="12" md="1" class="d-flex justify-center align-center greybackground" v-if="tabSelected != 3">
                <v-btn size="35" class="ml-1" icon="mdi-star" @click="frontArrowClicker">
                  <VIcon size="35" icon="mdi-menu-right" color="#64007d" />
                </v-btn>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </v-container>
      <VContainer class="bordercolorblack borderwidththin mt-4">
        <VRow class="mx-1 bottomborderclass">
          <VCol class="d-flex justify-left align-center" cols="12" md="12">
            <span class="generalprimarymediumfont">Total play time : </span>
            <span class="generalprimarymediumfont fontweightsemibold mx-1">
              {{ getPlayTime }}
            </span>
          </VCol>
        </VRow>
        <v-row class="mt-2 mx-1">
          <VCol cols="12" md="12">
            <div v-if="loading" class="d-flex justify-center align-center">
              <v-progress-circular color="#64007d" indeterminate></v-progress-circular>
            </div>
            <Chart :names="eventNames" :eventTimes="eventTimes" :totalValues="totalValues" :rawData="values"
              :key="getPlayTime" :topTabSelected="tabSelected" v-if="dataAvailable === true && loading === false"></Chart>
          </VCol>
        </v-row>
      </VContainer>
    </v-main>
  </v-app>
</template>
<script>
import Chart from "./Chart.vue";
import axios from "axios";
import lodash from "lodash";
import moment from "moment";
import Profile from "./Profile/Profile.vue";
import DateCard from "./Calendar/DateCard.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import { ref } from "vue";
import "@vuepic/vue-datepicker/dist/main.css";
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdb-vue-ui-kit";
import activitynames from "@/Files/activitynames.json";
import infoicontop from "@/assets/UI/infoicontop.png";
import { Popper } from "vue-use-popperjs";
import Popover from "./Popover/Popover.vue";
import router from "../../router";

export default {
  mounted() {
    this.checkforlogin();
  },
  components: {
    Chart,
    Profile,
    DateCard,
    VueDatePicker,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    Popper,
    Popover,
  },
  computer: {},
  data() {
    const dropdown1 = ref(false);
    return {
      data: [],
      infoicontop: infoicontop,
      groupedData: [],
      dates: [],
      values: [],
      totalValues: [],
      dataAvailable: false,
      dateSelectedIndex: 5,
      dateSelected: "",
      tabSelected: 0,
      tabDefault: "Day",
      startDate: this.convertDate(
        new Date(new Date().setDate(new Date().getDate() - 365))
      ),
      // startDate: this.convertDate(subtractFromDate(new Date(), { days: 365 })),
      endDate: this.convertDate(new Date()),
      datesArray: [],
      monthsArray: [],
      yearArray: [],
      mainArray: [],
      dateArrayIndex: 0,
      loading: true,
      userdata: {},
      dateForPicker: new Date(),
      datePickerUpperLimit: new Date(),
      datePickerLowerLimit: new Date(
        new Date().setDate(new Date().getDate() - 360)
      ),
      dropdown1,
      daterange: ref([
        new Date(new Date().setDate(new Date().getDate() - 7)),
        new Date(),
      ]),
      activitynames: activitynames,
      lastlogin: "",
      tootltiplastonlinemessage:
        "The \"Last Online\" refers to the most recent date and time when a learner engaged in activities online, and doesn'\t include any offline activities they may have done since those aren'\t tracked.",
    };
  },
  beforeMount() {
    // console.log("beforeMount");

  },
  watch: {
    dateForPicker(value, oldValue) {
      const date = this.convertDate(value);
      this.getTheSelectedDateIndex(date);
      this.getData();
      this.groupByData();
    },
    daterange(value, oldValue) {
      this.groupByData();
    },
  },
  computed: {
    getPlayTime() {
      const dict = this.getValueForDate(this.dateSelected);
      return dict.playTime;
    },
    eventNames() {
      // console.log("the totalValues is", this.totalValues);
      let retVal = this.totalValues.map((item) => {
        const name = item.event;

        if (name !== "PushNotification" && name !== "Help" && name !== "Tooltips") {
          // console.log("the name is", name, activitynames[name]);
          return activitynames[name];
        }
      });
      // console.log("the eventNames length is", retVal.length);
      // return ["test 1", "test 2", "test 3", "test 4", "test 5", "test 6"];
      return retVal.slice(0, 36);
    },
    eventTimes() {
      // console.log("the totalValues is", this.totalValues);
      // return [1, 2, 3, 4, 5, 6];
      let retVal = this.totalValues.map((item) => {
        if (name !== "PushNotification" && name !== "Help" && name !== "Tooltips") {

          let totalActivityTime = item.totalActivityTime ?? 0;
          //if totalActivityTime is more than 5 minutes, then set it to the respective seconds for 5 minutes
          // if (totalActivityTime > 300) {
          //   totalActivityTime = 300;
          // }
          // console.log("the totalActivityTime is", totalActivityTime);
          const time = totalActivityTime / 60;
          if (time < 1) {
            return Math.ceil(time);
          } else {
            return naiveRound(time, 0);
          }
        }
      });
      // console.log("the eventTimes length is", retVal.length);
      // return [1, 2, 3, 4, 5, 6];
      return retVal.slice(0, 36);
    },
    getDates() {
      if (this.tabSelected == 3) {
        return 0;
      }
      return this.mainArray[this.dateArrayIndex];
    },
    getColumns() {
      const a = this.tabDefault;
      if (this.tabSelected == 0) {
        return 1;
      } else if (this.tabSelected == 1) {
        return 2;
      } else {
        return 2;
      }
    },
  },
  methods: {
    checkforlogin() {
      // console.log("checkforlogin");
      //   let item = localStorage.getItem(this.LOGIN_ACCESSTOKEN);
      let item2 = localStorage.getItem(this.LOGIN_UID) ?? "";
      console.log("checkforlogin item2", item2);
      if (typeof item2 === "undefined" || item2.length === 0) {
        //Login push
        router.push({
          name: "Login",
        });
      } else {
        this.checkforentitlement();
      }
    },
    checkforentitlement() {
      const val = localStorage.getItem(this.CHECK_ENTITLEMENT) ?? "";
      if (val === "") {
        //No entitlement
        router.push({
          name: "Access",
        });
      } else {

        const item3 = JSON.parse(val)
        if (typeof item3 === "undefined" || item3.length === 0 || Object.keys(item3).length === 0) {
          //No entitlement
          router.push({
            name: "Access",
          });
        } else {
          this.$root.$emit("mySpecialName");
          let item = localStorage.getItem("currentstudent");
          this.userdata = JSON.parse(item); //this.$store.getters.getcurrentstudent;
          this.allDates();
        }
      }
    },
    getTheSelectedDateIndex(date) {
      for (const [index, element] of this.mainArray.entries()) {
        if (element.includes(date)) {
          this.dateArrayIndex = index;
          this.getData();
          return;
        }
      }
    },
    convertDate(date) {
      return date.toLocaleDateString("pt-br").split("/").reverse().join("-");
    },
    dateDisabled(date) {
      if (date in this.groupedData == false) {
        return true;
      } else {
        return false;
      }
    },
    getValueForDate(date) {
      if (date in this.groupedData == false) {
        return { playTime: "No Play time", available: false };
      } else {
        // console.log("groupedData", this.groupedData);
        const value = this.groupedData[date];
        let playTime = 0;
        value.forEach(function (val) {
          if (val.totalActivityTime == null) {
            val.totalActivityTime = 0;
          }
          // console.log("the val is", val.totalActivityTime);
          playTime = playTime + Number(val.totalActivityTime);
        });
        // console.log("the playtime is", playTime);
        if (playTime >= 60) {
          let h = Math.floor(playTime / 3600);
          let m = Math.floor((playTime % 3600) / 60);
          let s = Math.floor((playTime % 3600) % 60);
          if (h != 0) {
            return {
              playTime: h + "hr " + m + "min",
              available: true,
            };
          }
          return {
            playTime: m + "min " + s + "sec",
            available: true,
          };
        } else {
          return { playTime: playTime + " sec", available: true };
        }
      }
    },
    dayClicked() {
      this.tabDefault = "Day";
      this.dropdown1 = false;
      this.changeRoute();
    },
    monthClicked() {
      this.tabDefault = "Month";
      this.dropdown1 = false;
      this.changeRoute();
    },
    yearClicked() {
      this.tabDefault = "Year";
      this.dropdown1 = false;
      this.changeRoute();
    },
    customClicked() {
      this.tabDefault = "Custom";
      this.dropdown1 = false;
      this.changeRoute();
    },
    changeRoute() {
      const a = this.tabDefault;
      this.dateArrayIndex = 0;
      if (a == "Day") {
        this.dateSelected = this.convertDate(new Date());
        this.mainArray = this.datesArray;
        this.tabSelected = 0;
      } else if (a == "Month") {
        this.dateSelected = this.convertDate(new Date()).slice(0, 7);
        this.mainArray = this.monthsArray;
        this.tabSelected = 1;
      } else if (a == "Year") {
        this.dateSelected = this.convertDate(new Date()).slice(0, 4);
        this.mainArray = this.yearArray;
        this.tabSelected = 2;
      } else {
        this.tabSelected = 3;
        this.dateSelected = "Custom";
      }
      this.dateSelectedIndex = this.setDateSelectedIndex();
      this.groupByData();
    },
    allDates() {
      const listDate = [];
      const startDate = this.startDate;
      const endDate = this.endDate;
      const dateMove = new Date(endDate);
      let eDate = endDate;
      while (eDate > startDate) {
        eDate = dateMove.toISOString().slice(0, 10);
        listDate.push(eDate);
        dateMove.setDate(dateMove.getDate() - 1);
      }
      this.dates = listDate;
      var tempDate = [];
      var tempMonth = [];
      var tempMonth = [];
      var tempMonthAll = [];
      var tempYear = [];
      for (let val of listDate) {
        tempDate.push(val);
        if (tempDate.length == 6) {
          this.datesArray.push(tempDate.reverse());
          tempDate = [];
        }
        let month = val.slice(0, 7);
        if (tempMonthAll.includes(month) == false) {
          tempMonthAll.push(month);
          tempMonth.push(month);
          if (tempMonth.length == 4) {
            this.monthsArray.push(tempMonth.reverse());
            tempMonth = [];
          }
        }

        let year = val.slice(0, 4);
        if (tempYear.includes(year) == false) {
          tempYear.push(year);
        }
      }
      this.yearArray.push(tempYear.reverse());
      this.mainArray = this.datesArray;
      this.getData();
      this.loadJsonData();
    },
    getData() {
      let date = this.datesArray[this.dateArrayIndex][this.dateSelectedIndex];
      this.dateSelected = date;
    },
    loadJsonData() {
      this.loading = true;
      var startdate = this.startDate;
      var enddate = this.endDate;
      // console.log("the start date is", startdate);
      // startdate = "2023-10-31";
      // enddate = "2024-02-24";
      let url =
        //this.BASE_URL +
        "https://services.explorecosmo.com/analytics/getactivities?cid=" +
        // "http://localhost:8080/analytics/getactivities?cid=" +
        this.userdata.cid +
        "&startdate=" +
        startdate +
        "&enddate=" +
        enddate;
      axios
        .get(url)
        .then((res) => {
          this.data = JSON.parse(JSON.stringify(res.data));
          this.data = res.data;
          this.groupByData();
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    groupByData() {
      if (this.tabSelected == 0) {
        this.groupedData = this.dayGroup(this.data);
        if (this.lastlogin === "") {
          const arr = Object.keys(this.groupedData);
          this.lastlogin = arr[arr.length - 1];
          this.lastlogin = moment(this.lastlogin).format("DD MMM YYYY");
        }
      } else if (this.tabSelected == 1) {
        this.groupedData = this.monthGroup(this.data);
      } else if (this.tabSelected == 2) {
        this.groupedData = this.yearGroup(this.data);
      } else {
        this.groupedData = this.customGroup(this.data);
      }
      this.processData(this.groupedData);
    },
    dayGroup(newData) {
      return lodash.groupBy(newData, "date");
    },
    monthGroup(newData) {
      let hash = Object.create(null);
      let result = {};
      newData.forEach(function (o) {
        var key = o.date.slice(0, 7);
        if (!hash[key]) {
          hash[key] = [];
          result[key] = hash[key];
          // result.push({key:hash[key]});
        }
        hash[key].push(o);
      });
      return result;
    },
    yearGroup(newData) {
      let hash = Object.create(null);
      let result = {};
      newData.forEach(function (o) {
        var key = o.date.slice(0, 4);
        if (!hash[key]) {
          hash[key] = [];
          result[key] = hash[key];
          // result.push({key:hash[key]});
        }
        hash[key].push(o);
      });
      return result;
    },
    customGroup(newData) {
      const sd = this.convertDate(this.daterange[0]);
      const ed = this.convertDate(this.daterange[1]);
      const listDate = [];
      const startDate = sd;
      const endDate = ed;
      const dateMove = new Date(endDate);
      let eDate = endDate;
      while (eDate > startDate) {
        eDate = dateMove.toISOString().slice(0, 10);
        listDate.push(eDate);
        dateMove.setDate(dateMove.getDate() - 1);
      }
      let hash = Object.create(null);
      let result = {};
      var values = [];
      newData.forEach(function (o) {
        var key = o.date;
        if (listDate.includes(key)) {
          values.push(o);
        }
      });
      result["Custom"] = values;
      return result;
    },
    processData(data) {
      this.loading = false;
      if (this.tabSelected === 3) {
      } else {

        if (this.dateSelected in data == false) {
          this.dataAvailable = false;
          // console.log("the data is not available");

          return;
        }
      }
      this.dataAvailable = true;
      // console.log("the data is", data);
      let result = data[this.dateSelected];
      this.values = result;
      // console.log("the values is", this.values);
      this.totalValues = sumByKey(this.values, "event", "totalActivityTime")
        .sort((a, b) => Number(a.totalActivityTime) - Number(b.totalActivityTime))
        .reverse();

      // console.log("the totalValues is", this.totalValues);
    },
    dateClicked(val, index) {
      if (this.dateDisabled(val) === true) {
        return;
      }
      this.dateSelected = val;
      this.dateSelectedIndex = index;
      this.groupByData();
    },
    showEmpty() { },
    setDateSelectedIndex() {
      if (this.tabSelected === 0) {
        return 5;
      } else if (this.tabSelected === 1) {
        return 3;
      } else if (this.tabSelected === 2) {
        return 1;
      } else {
        return 0;
      }
    },
    needBorder(index) {
      if (index === this.dateSelectedIndex) {
        return true;
      } else {
        return false;
      }
    },
    backArrowClicked() {
      if (this.dateArrayIndex < this.mainArray.length - 1) {
        this.dateArrayIndex = this.dateArrayIndex + 1;
      }

      this.dateSelectedIndex = this.setDateSelectedIndex();
      this.dataAvailable = false;
      this.getData();
      this.groupByData();
    },
    frontArrowClicker() {
      if (
        this.dateArrayIndex <= this.mainArray.length - 1 &&
        this.dateArrayIndex != 0
      ) {
        this.dateArrayIndex = this.dateArrayIndex - 1;
      }
      this.dateSelectedIndex = this.setDateSelectedIndex();
      this.dataAvailable = false;
      this.getData();
      this.groupByData();
    },

  },
};
const sumByKey = (arr, key, value) => {
  // let dict = {}
  const map = new Map();
  for (const obj of arr) {
    const currSum = map.get(obj[key]) || 0;
    map.set(obj[key], currSum + Number(obj[value]));

  }

  const res = Array.from(map, ([k, v]) => ({ [key]: k, [value]: v }));
  // console.log("the map is",res)
  return res;
};

function naiveRound(num, decimalPlaces = 0) {
  var p = Math.pow(10, decimalPlaces);
  return Math.round(num * p) / p;
}

function sortObjects(arr) {
  const { compare } = Intl.Collator("en-US");
  return arr.sort((a, b) => compare(a.totalActivityTime, b.totalActivityTime));
}

function convert(date) {
  const d = Date.parse(date);
  const date_obj = new Date(d);
  return `${date_obj.getFullYear()}-${date_obj.toLocaleString("default", {
    month: "2-digit",
  })}-${date_obj.toLocaleString("default", { day: "2-digit" })}`;
}

const subtractFromDate = (
  date,
  { years, days, hours, minutes, seconds, milliseconds } = {}
) => {
  const millisecondsOffset = milliseconds ?? 0;
  const secondsOffset = seconds ? 1000 * seconds : 0;
  const minutesOffset = minutes ? 1000 * 60 * minutes : 0;
  const hoursOffset = hours ? 1000 * 60 * 60 * hours : 0;
  const daysOffset = days ? 1000 * 60 * 60 * 24 * days : 0;
  const dateOffset =
    millisecondsOffset +
    secondsOffset +
    minutesOffset +
    hoursOffset +
    daysOffset;

  let newDate = date;
  if (years) newDate = date.setFullYear(date.getFullYear() - years);
  newDate = new Date(newDate - dateOffset);

  return newDate;
};
</script>

<style>
.newtoggle {
  flex-direction: column;
  height: 50%;
  width: 100%;
}

.datepicker {
  flex-direction: column;
  height: 50%;
  width: 100%;
  padding-top: 25px;
}

.container {
  min-height: 175px;
}

.topContainer {
  /* min-height:100%; */
  min-height: 150px;
}

.datesessioncolumn {
  /* background-color: #f5f5f5; */
  background-color: blue;
  /* flex-flow: row nowrap; */
}

.profileclass {
  min-height: 100%;
  max-width: 100%;
  background-color: #f5f5f5;
}

.dateTop {
  background-color: green;
  height: 40%;
  min-width: 100%;
  /* min-width:100%; */
}

.btncontainer {
  min-height: 60%;
  height: 60%;
  min-width: 100%;
}

.sessiontext {
  color: #64007d;
  font-weight: 600;
  height: 100%;

  /* word-break: break-word; */
  /* min-width: 100%; */
}

.buttons {
  min-height: 100%;
  /* min-width:200px; */
}

.chartcolumn {
  border-width: 1px;
  border-color: #64007d;
}

.datepickerclass {
  border-color: #64007d;
  border-radius: 5px;
  text-align: center;
  --vdp-border-radius: 0px;
  --vdp-elem-border-radius: 0px;
  --vdp-divider-color: #ffffff;
}

.datecardclass {
  max-height: 60px !important;
  min-height: 60px !important;
}

.dropdownclass {
  width: 175px;
  height: 40px;
  color: #64007d;
}

.dropdodaterangeclasswnclass {
  width: 350px;
  height: 40px;
}

.dropdowntoggleclass {
  width: 170px;
  height: 36px;
  color: #64007d;
}

.dropdown-toggle {
  color: #64007d;
  font-weight: 700px;
}

.v-tooltip__content {
  color: blue !important;
  background-color: red;
}

.infoicontopclass {
  height: 15px;
  width: 15px;
}
</style>

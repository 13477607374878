<template>
  <VRow class="mt-4">
    <VCol cols="12" md="4"></VCol>
    <VCol cols="12" md="4" class="ma-4 greybackground containerclass">
      <div class="">
        <div class="ma-6 namenormaltitle primarycolor fontweightsemibold">
          Login
        </div>
        <div class="">
          <div v-if="error" class="alert alert-danger">{{ error }}</div>
          <form action="#" @submit.prevent="login">
            <div for="email" class="generalblackfont ml-6">Email Address</div>
            <div class="mt-2 ml-6 col-md-10">
              <input
                id="email"
                type="email"
                class="form-control"
                name="email"
                value
                required
                autofocus
                v-model="email"
              />
            </div>

            <div for="password" class="generalblackfont mt-2 ml-6">Password</div>

            <div class="mt-2 ml-6 col-md-10">
              <input
                id="password"
                type="password"
                class="form-control"
                name="password"
                required
                v-model="password"
              />
            </div>

            <div class="mt-4 col-md-6 offset-md-6">
              <v-btn
                class="generalprimaryfont text-decoration-underline no-uppercase"
                @click="forgotpasswordclicked"
                variant="text"
                >Forgot Password?</v-btn
              >
            </div>

            <div class="mt-4 ml-8 col-md-10">
              <button
                width="100%"
                type="submit"
                class="primarybackground generalwhitemediumfont btnauthclass"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </VCol>
    <VCol cols="12" md="4"> </VCol>
  </VRow>
</template>

<script>
import { ref, defineComponent } from "vue";
import firebase from "../firebaseconfig";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import axios from "axios";
import router from "../../router";

export default defineComponent({
  data() {
    return {
      email: ref(""),
      password: ref(""),
      uid: "",
      accessToken: "",
      error: ref(null),
    };
  },
  mounted() {
    this.$root.$emit("mySpecialName");
  },
  methods: {
    login() {
      signInWithEmailAndPassword(getAuth(), this.email, this.password).then(
        (user) => {
          this.uid = user.user.uid;
          this.accessToken = user.user.accessToken;
          // console.log("====user", user);
          localStorage.setItem(this.LOGIN_ACCESSTOKEN, user.user.accessToken);
          localStorage.setItem(this.LOGIN_UID, user.user.uid);
          this.checkForEntitlements(user.user.uid);
        },
        (err) => {
          console.log(err);
          this.error = err;
        }
      );
    },
    checkForEntitlements(pid) {
      // console.log(this.BASE_URL + "checkentitlement?pid=" + pid);
      axios
        .get(this.BASE_URL + "checkentitlement?pid=" + pid)
        .then((res) => {
          const entitlements = res.data.entitlements;
          localStorage.setItem(
            this.CHECK_ENTITLEMENT,
            JSON.stringify(entitlements)
          );
          router.push({
            name: "Initial",
          });
        })
        .catch((error) => {
          console.log("error", error);
          this.error = error;
        });
    },
    forgotpasswordclicked() {
      router.push({
        name: "Forgot",
      });
    },
  },
});
</script>

<style scoped>
.containerclass {
  height: 450px;
  border-radius: 5px;
}
</style>

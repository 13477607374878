import { createWebHistory, createRouter } from "vue-router";
import Dashboard from '@/components/Dashboard/DataValues'
import HomeView from '@/components/Home/HomeView'
import VerifiedView from '@/components/Home/VerifiedView'
import Login from '@/components/Authentication/Login'
import Signup from '@/components/Authentication/Signup'
import ForgotPassword from '@/components/Authentication/ForgotPassword'
import Initial from '@/components/Authentication/Initial'
import Entitlement from '@/components/Authentication/Entitlement'

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: HomeView,
    props:true
  },
  { 
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    props:true
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    props:true
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    props:true
  },{
    path: '/forgot',
    name: 'Forgot',
    component: ForgotPassword,
    props:true
  },
  {
    path: '/',
    name: 'Initial',
    component: Initial,
    props:true
  },
  {
    path: '/contactus',
    name: 'Access',
    component: Entitlement,
    props:true
  },
  {
    path: '/userverified',
    name: 'Verified',
    component: VerifiedView,
    props:true
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;



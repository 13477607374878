<!-- v-bind:style=" needBorder ? 'background-color: #64007d; color:white' : 'border: none;' " -->
<!-- v-bind:class="(needBorder ? 'datecardSelected' : 'datecard')" -->
<template>
  <v-layout>
    <v-container fluid :class="getClassName">
      <VRow no-gutters clas="">
        <VCol
          cols="12"
          md="12"
          class="bordercolorprimary  cardclass"
        >
          <div v-if="tabSelected === 0" id="day" class="mt-1 text-center">
            {{ getWeekDay }}
          </div>
          <div v-if="tabSelected === 0" class="text-caption text-center">
            {{ getDay }} {{ getMonth }}
          </div>
          <div v-if="tabSelected === 1" class="mt-1 text-center">
            {{ getMonth }}
          </div>
          <div v-if="tabSelected === 1" class="text-center">
            {{ getYear }}
          </div>
          <div v-if="tabSelected === 2" class="ma-4 text-center justify-center yearclass">
            {{ getYear }}
          </div>
        </VCol>
      </VRow>

      <!-- <VCol cols="12" md="12"></VCol> -->
      <!-- <v-card no-gutters :class="getClassName">
        <div v-if="tabSelected === 0" id="day" class="pt-1">
          {{ getWeekDay }}
        </div>
        <div v-if="tabSelected === 0" class="text-caption month">
          {{ getDay }} {{ getMonth }}
        </div>
        
      </v-card> -->
    </v-container>
  </v-layout>
</template>

<script>
export default {
  components: {},
  computer: {},
  props: ["dateValue", "tabSelected", "needBorder", "data", "datadisabled"],
  computed: {
    getWeekDay() {
      return new Date(this.dateValue).toLocaleString("en-US", {
        weekday: "short",
      });
    },
    getDay() {
      return new Date(this.dateValue).toLocaleString("en-US", {
        day: "2-digit",
      });
    },
    getMonth() {
      return new Date(this.dateValue).toLocaleString("en-US", {
        month: "short",
      });
    },
    getYear() {
      return new Date(this.dateValue).getFullYear();
    },
    getPlayTime() {
      return this.data.playTime;
    },
    getClassName() {
      if (this.datadisabled) {
        return "pa-0 ma-0 datadisabled contianerclass borderwidththinmorecurve";
      } else {
        if (this.needBorder) {
          return "pa-0 ma-0 datecardSelected contianerclass borderwidththinmorecurve";
        } else {
          return "pa-0 ma-0 datecard contianerclass borderwidththinmorecurve";
        }
      }
    },
  },
};
</script>
<style scoped>
.datecard {
  text-align: center;
  border-width: 1px;
  border-color: #64007d;
  color: #64007d;
  margin-left: 2px;
  margin-right: 2px;
  position: relative;
  /* max-height: 52px; */
}
.datecardSelected {
  text-align: center;
  background-color: #64007d;
  color: white;
  margin-left: 2px;
  margin-right: 2px;
  position: relative;
}
.month {
  padding-left: 4px;
}

.datadisabled {
  text-align: center;
  background-color: #f5f5f5;
  color: #bbbbbb;
  margin-left: 2px;
  margin-right: 2px;
  position: relative;
  border-color: #bbbbbb;
  border-width: 1px;
}

#common {
  font-size: 14px;
}
.contianerclass {
  min-height: 100%;
}
.cardclass {
  height: 60px;
}
</style>

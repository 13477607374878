<template>
  <v-container
    class="bordercolorotherpink borderwidththin sessionmetric mt-1 shadowclass"
    grid-list-xl
  >
    <VRow
      v-if="dataAvailable === true"
      :key="dataAvailable"
      class="bottomborderclass"
    >
      <VCol cols="12" md="12">
        <div style="text-align: center">
          <span class="generalprimarymediumfont fontweightsemibold">{{
            getEventName(data.event)
          }}</span>
          <span class="ml-2 generalprimarymediumfont"
            >({{ sessionItems.length }} Sessions)</span
          >
        </div>
      </VCol>
    </VRow>
    <VRow v-if="dataAvailable === false">
      <VCol class="d-flex justify-center align-center">
        <div class="chooseclass">
          Choose an activity to access the details here
        </div>
      </VCol>
    </VRow>
    <VRow
      class="bottomborderclass sessioncontainerclass"
      v-if="dataAvailable === true"
    >
      <VCol
        cols="12"
        md="2"
        class="d-flex justify-center align-center"
        style="height: 50px"
      >
        <SessionCard
          @click="previousButtonPressed(value, index)"
          :isback="true"
          :isfront="false"
          :previousavailable="ispreviousAvailable()"
          v-if="sessionItems.length > sessionrowgrid"
        ></SessionCard>
      </VCol>
      <VCol
        cols="12"
        md="8"
        class="d-flex justify-center align-center"
        style="height: 50px"
      >
        <SessionCard
          v-for="(value, index) in sessionItemsToShow()"
          :key="index"
          :isback="false"
          :isfront="false"
          :sessionValue="value"
          @click="sessionPressed(value, index)"
          :needsborder="needsborder(index)"
          class="ml-2 mr-2"
        ></SessionCard>
      </VCol>
      <VCol
        cols="12"
        md="2"
        class="d-flex justify-center align-center"
        style="height: 50px"
      >
        <SessionCard
          :forwardavailable="isforwardAvailable()"
          @click="forwardButtonPressed(value, index)"
          :isback="false"
          :isfront="true"
          v-if="sessionItems.length > sessionrowgrid"
        ></SessionCard>
      </VCol>
    </VRow>
    <VRow v-if="dataAvailable === true" :key="dataAvailable" class="">
      <VCol cols="12" md="2" v-if="topTabSelected === 0"></VCol>
      <VCol
        cols="12"
        :md="getcolumnwidthforsessiontime"
        class="d-flex justify-center align-center generalblacksmallfont"
      >
        <img class="iconsessionclass" :src="sessionstimeicon" />
        <span class=""> {{ getSessionTime }} </span>
      </VCol>
      <VCol
        cols="12"
        :md="getcolumnwidthforsessionplace"
        class="d-flex align-center generalblacksmallfont"
      >
        <img class="iconsessionclass" :src="sessionlocationicon" />
        <span class="sessionplaceclass"> {{ getSessionPlace }} </span>
      </VCol>
    </VRow>
    <VRow class="sessionmetric mt-6" v-if="dataAvailable === true">
      <VCol
        v-for="(value, index) in getAllAvailableSession"
        :key="index"
        cols="12"
        :md="getcolumnWidth(value)"
      >
        <VRow no-gutters>
          <VCol cols="12" :md="getcolumnWidthEmpty(value)"></VCol>
          <VCol cols="12" :md="getcolumnWidthImage(value)">
            <img class="thumbnailclass ml-2" :src="activityImages[value]" />
          </VCol>
          <VCol cols="12" :md="getcolumnWidthMetric(value)">
            <div v-if="value !== 'avgResponseTime'">
              <div class="generalprimarymediumfont fontweightsemibold ml-3">
                {{ getMetricValue(value,false) }}
              </div>
              <div class="d-flex align-center ml-3">
                <span class="textcolor textsmallsize" style="text-align: left">
                  {{ getMetricName(value) }}
                </span>
                <div>
                  <img class="ml-1 infoiconclass" :src="infoicon" />
                  <v-tooltip
                    max-width="300"
                    activator="parent"
                    location="bottom"
                    color="black"
                  >
                    {{ gettooltipmessage(value) }}
                  </v-tooltip>
                </div>
              </div>
            </div>
            <div v-if="value === 'avgResponseTime'">
              <div class="d-flex align-center ml-3">
                <span class="generalprimarymediumfont fontweightsemibold">
                  {{ getMetricValue(value,true) }}
                </span>
                <VBtn
                  class="ml-3"
                  icon
                  color="default"
                  size="x-small"
                  variant="text"
                  @click="dropdownclicked()"
                >
                  <VIcon size="24" :icon="getdropdownicon" />
                </VBtn>
              </div>

              <div class="d-flex align-center ml-3">
                <span class="textcolor textsmallsize" style="text-align: left">
                  {{ getMetricName(value) }}
                </span>

                <div>
                  <img class="ml-1 infoiconclass" :src="infoicon" />
                  <v-tooltip
                    max-width="300"
                    activator="parent"
                    location="bottom"
                    color="black"
                  >
                    {{ gettooltipmessage(value) }}
                  </v-tooltip>
                </div>
              </div>

              <MetricAdditional
                v-if="isdropdownexpanded"
                :dropdownvalues="dropdownValues(value)"
                :showCosmoidLayout="isMetricShowCosmoid(value)"
                :colourOrder="getMetricValue('colourOrder', false)"
              >
              </MetricAdditional>
            </div>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </v-container>
</template>
<script>
import avgResponseTime from "@/assets/Sessions/avgResponseTime.png";
import responseTimes from "@/assets/Sessions/responseTimes.png";
import correctResponse from "@/assets/Sessions/correctResponse.png";
import difficulty from "@/assets/Sessions/difficulty.png";
import duration from "@/assets/Sessions/duration.png";
import minDuration from "@/assets/Sessions/duration.png";
import maxDuration from "@/assets/Sessions/duration.png";
import durationButtonsPressed from "@/assets/Sessions/durationButtonsPressed.png";
import intervals from "@/assets/Sessions/intervals.png";
import sensitivity from "@/assets/Sessions/sensitivity.png";
import musicTrack from "@/assets/Sessions/musicTrack.png";
import music from "@/assets/Sessions/music.png";
import notes from "@/assets/Sessions/music.png";
import numberOfDevices from "@/assets/Sessions/numberOfDevices.png";
import rounds from "@/assets/Sessions/rounds.png";
import score from "@/assets/Sessions/score.png";
import sequenceMode from "@/assets/Sessions/sequenceMode.png";
import needsSimultaneousPress from "@/assets/Sessions/needsSimultaneousPress.png";
import totalActivityTime from "@/assets/Sessions/totalActivityTime.png";
import totalTimesPressed from "@/assets/Sessions/totalTimesPressed.png";
import sessionmetrics from "@/Files/sessionmetrics.json";
import activitynames from "@/Files/activitynames.json";
import activityspecificpresses from "@/Files/activityspecificpresses.json";
import SessionCard from "./Sessions/SessionCard.vue";
import sessionstimeicon from "@/assets/UI/sessionstimeicon.png";
import sessionlocationicon from "@/assets/UI/sessionlocationicon.png";
import moment from "moment";
import infoicon from "@/assets/UI/infoicon.png";
import metrichelp from "@/Files/metrichelp.json";
import musictracks from "@/Files/musictracks.json";
import MetricAdditional from "./MetricAdditional/MetricAdditionDetails.vue";
// import { f } from "vueye-table";

export default {
  props: ["data", "datavisible", "allSessionData", "topTabSelected"],
  components: {
    SessionCard,
    MetricAdditional,
  },
  data() {
    return {
      sessionmetrics: sessionmetrics,
      activitynames: activitynames,
      activityspecificpresses: activityspecificpresses,
      activityImages: {
        avgResponseTime: avgResponseTime,
        responseTimes: responseTimes,
        correctResponse: correctResponse,
        difficulty: difficulty,
        duration: duration,
        durationButtonsPressed: durationButtonsPressed,
        intervals: intervals,
        sensitivity: sensitivity,
        musicTrack: musicTrack,
        music: music,
        numberOfDevices: numberOfDevices,
        rounds: rounds,
        score: score,
        sequenceMode: sequenceMode,
        needsSimultaneousPress: needsSimultaneousPress,
        totalActivityTime: totalActivityTime,
        totalTimesPressed: totalTimesPressed,
        minDuration: minDuration,
        maxDuration: maxDuration,
        notes: notes
      },
      sessionSelected: 0,
      gridindex: 0,
      isnextavailable: false,
      ispreviousavailable: false,
      sessionstimeicon: sessionstimeicon,
      sessionlocationicon: sessionlocationicon,
      infoicon: infoicon,
      metrichelp: metrichelp,
      isdropdownexpanded: false,
    };
  },
  computed: {
    getAllAvailableSession: function () {
      if (
        typeof this.data === "undefined" ||
        Object.keys(this.data).length === 0
      ) {
        return [];
      }
      var metrics = sessionmetrics[this.data.event.toLowerCase()] ?? [];
      if (metrics.length == 0) {
        metrics = sessionmetrics["default"];
      }
      return metrics;
    },
    sessionItems: function () {
      var arr = [];
      this.allSessionData.forEach((val, index) => {
        arr.push(index + 1);
      });
      return arr;
    },
    dataAvailable: function () {
      if (
        typeof this.data === "undefined" ||
        Object.keys(this.data).length === 0
      ) {
        return false;
      }
      return true;
    },
    getSessionTime: function () {
      const index = this.gridindex * this.sessionrowgrid + this.sessionSelected;
      var d = this.allSessionData[index];
      if (this.topTabSelected === 0) {
        return moment(d.time * 1000).format("HH:mm A");
      } else {
        return moment(d.time * 1000).format("DD MMM YY, HH:mm A");
      }
    },
    getSessionPlace: function () {
      const index = this.gridindex * this.sessionrowgrid + this.sessionSelected;
      var d = this.allSessionData[index];
      const getCountryNames = new Intl.DisplayNames(["en"], { type: "region" });
      var country = "";
      if (d.mp_country_code !== "") {
        country = getCountryNames.of(d.mp_country_code);
      }
      if (country === "undefined" || country === "") {
        country = d.$country;
      }
      return d.$city + ", " + country;
    },
    getcolumnwidthforsessiontime: function () {
      if (this.topTabSelected === 0) {
        return 3;
      }
      return 6;
    },
    getcolumnwidthforsessionplace: function () {
      if (this.topTabSelected === 0) {
        return 7;
      }
      return 6;
    },
    getdropdownicon: function () {
      if (this.isdropdownexpanded) {
        return "mdi-menu-up";
      } else {
        return "mdi-menu-down";
      }
    },
  },
  watch: {
    data: function (newVal, oldVal) {
      // watch it
      this.sessionSelected = 0;
    },
  },
  methods: {
    getcosmoidcolour(index) {
      let colours = ["red", "green", "blue", "yellow", "orange", "purple"];
      return "background-color: " + colours[index];
    },
    getheight(value) {
      return 40;
    },
    getwidth(value) {
      return 40;
    },
    getcolumnWidth(value) {
      if (
        value === "responseTimes" ||
        value === "avgResponseTime" ||
        value === "intervals" ||
        value === "notes"
      ) {
        return 12;
      } else {
        return 6;
      }
    },
    getcolumnWidthImage(value) {
      if (
        value === "responseTimes" ||
        value === "avgResponseTime" ||
        value === "intervals" ||
        value === "notes"
      ) {
        return 1;
      } else {
        return 2;
      }
    },
    getcolumnWidthMetric(value) {
      if (
        value === "responseTimes" ||
        value === "avgResponseTime" ||
        value === "intervals" ||
        value === "notes"
      ) {
        return 11;
      } else {
        return 10;
      }
    },
    getcolumnWidthEmpty(value) {
      if (
        value === "responseTimes" ||
        value === "avgResponseTime" ||
        value === "intervals" ||
        value === "notes"
      ) {
        
        return 0;
      } else {
        return 0;
      }
    },
    getMetricName(metric) {
      if (metric.includes("numberOfDevices")) {
        return "Cosmoids";
      }
      const result = metric.replace(/([A-Z]{1,})/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;
    },
    getMetricValue(variable, isValue) {
      if (this.dataAvailable === false) {
        return;
      }
      const index = this.gridindex * this.sessionrowgrid + this.sessionSelected;
      // console.log("index is", index);
      var d = this.allSessionData[index];
      if (variable != "") {
        // console.log("variable is", variable, d);
        switch (variable) {
          case "avgResponseTime":
            var avgResponseTime = "";
            if (this.data.event == "teamalertness") {
              avgResponseTime = d.avgResponseTimes ?? "";
            } else {
              avgResponseTime = d.avgResponseTime ?? "";
            }
            avgResponseTime = avgResponseTime.replace(/^\[([\s\S]*)]$/, "$1");
            if(isValue === true){
              return this.getAverageOfArray(avgResponseTime)
            }
            return avgResponseTime
           
          case "responseTimes":
            const responseTimes = d.responseTimes ?? "";
            return responseTimes.replace(/^\[([\s\S]*)]$/, "$1");
          case "difficulty":
            if (d.difficulty == "easy") {
              return "Beginner";
            } else if (d.difficulty == "medium") {
              return "Intermediate";
            } else if (d.difficulty == "hard") {
              return "Advanced";
            } else {
              return "Beginner";
            }
            // return d.difficulty ?? "easy";
          case "duration":
            var durations = d.duration ?? 0;
            
            if (durations == 0) {
              return durations + " sec";
            }
            if (durations >= 60) {
              let h = Math.floor(durations / 3600);
              let m = Math.floor((durations % 3600) / 60);
              let s = Math.floor((durations % 3600) % 60);
              if (h != 0) {
                return h + " hr " + m + " min";
              }

              if (s == 0) {
                return m + " min ";
              }

              if (m == 0) {
                return durations + " sec";
              }

              return m + " min " + s + " sec";
            } else {
              // console.log("durations is", durations, Math.floor(durations));
              if (Math.floor(durations) * 10 === durations * 10) {
                return Math.floor(durations) + " sec";
              }
              return durations + " sec";
            }
          case "durationButtonsPressed":
            let totald = d.durationButtonsPressed ?? 0;
            totald = naiveRound(totald, 0);

            if (totald == 0) {
              return totald + " sec";
            }
            if (totald >= 60) {
              let h = Math.floor(totald / 3600);
              let m = Math.floor((totald % 3600) / 60);
              let s = Math.floor((totald % 3600) % 60);
              if (h != 0) {
                return h + " hr " + m + " min";
              }

              if (s == 0) {
                return m + " min ";
              }

              if (m == 0) {
                return totald + " sec";
              }

              return m + " min " + s + " sec";
            } else {
              return totald + " sec";
            }
          case "totalTimesPressed":
            // console.log("totalTimesPressed is", d.totalTimesPressed);
            if (this.data.event == "rain" || this.data.event == "snow") {
              // console.log("specific event pressed", d);
              return this.getSpecificEventPressed(d);
            }
            return d.totalTimesPressed ?? "0";
          case "score":
            if (this.data.event === "showdown") {
              const score1 = d.score1 ?? "0";
              const score2 = d.score2 ?? "0";
              return score1 + " vs " + score2;
            }
            return d.score ?? "0";
          case "intervals":
            return d.intervals ?? "[]";
          case "sensitivity":
            return d.sensitivity ?? "medium";
          case "musicTrack":
            const track = d.musicTrack ?? "Off";
            
            if (track == "off") {
              return "Music Track Off";
            } else {
              if (typeof track == "number") {
                return "Apple Music Track";
              } else if (track.toLowerCase().includes("spotify")) {
                return "Spotify Track";
              } else {
                // const tracks = track.split("/");
                // let trackname = tracks[tracks.length - 1];
                // const trs = trackname.split(".");
                // return trs[0];
                // Extract the filename from the trackPath
                const filename = track.split('/').pop().split('.').shift();
                // Find the track object with the matching filename
                const trackFiltered = musictracks.find(track => track.filename === filename);

                // Return the corresponding name if found, otherwise return the original path
                return trackFiltered ? trackFiltered.name : track;
              }
            }
          case "needsSimultaneousPress":
            const simul = d.needsSimultaneousPress ?? "true";
            if (simul == "true") {
              return "Yes";
            } else {
              return "No";
            }

          case "intervals":
            return d.intervals ?? "[]";
          case "numberOfDevices":
            return d.numberOfDevices ?? "0";
          case "rounds":
            return d.rounds ?? "0";
          case "sequenceMode":
            return d.sequenceMode ?? "serial";
          case "totalActivityTime":
            let totalActivityTime = d.totalActivityTime ?? 0;

            // console.log("totalActivityTime is", totalActivityTime);

            let duration = d.duration ?? 0;
            duration = duration * 60;

            if (totalActivityTime == 0) {
              return totalActivityTime + " sec";
            }
            if (totalActivityTime >= 60) {
              let h = Math.floor(totalActivityTime / 3600);
              let m = Math.floor((totalActivityTime % 3600) / 60);
              let s = Math.floor((totalActivityTime % 3600) % 60);
              if (h != 0) {
                return h + " hr " + m + " min";
              }
              if (s == 0) {
                return m + " min ";
              }

              if (m == 0) {
                return totalActivityTime + " sec";
              }

              return m + " min " + s + " sec";
            } else {
              if (Math.floor(totalActivityTime) * 10 === totalActivityTime * 10) {
                return Math.floor(totalActivityTime) + " sec";
              }
              return totalActivityTime + " sec";
            }

          case "minDuration":
            const minDuration = d.minDuration ?? "0";
            if (Math.floor(minDuration) * 10 === minDuration * 10) {
              return Math.floor(minDuration) + " sec";
            }
            return minDuration + " sec";
          case "maxDuration":
            const maxDuration = d.maxDuration ?? "0";
            if (Math.floor(maxDuration) * 10 === maxDuration * 10) {
              return Math.floor(maxDuration) + " sec";
            }
            return maxDuration + " sec";
          case "notes":
            const notes = d.notes ?? "";
            return notes
            case "colourOrder":
              var cosmoColours = d.cosmoColours.toLowerCase() ?? "Blue,Green,Yellow,Orange,Red,Purple".toLowerCase() // ["Blue", "Green", "Yellow", "Orange", "Red", "Purple"]
              cosmoColours = cosmoColours.split(',')
              // console.log("colourOrder is", cosmoColours)
              return cosmoColours
          default:
            return "0";
        }
      } else {
        return "";
      }
    },
    capitalise(variable) {
      return variable[0].toUpperCase() + variable.slice(1).toLowerCase();
    },
    getEventName(name) {
      return activitynames[name];
    },
    getSpecificEventPressed(value) {
      let arr = activityspecificpresses[this.data.event];

      var totalpresses = 0;
      for (const d of arr) {
        const press = value[d] ?? 0;
        totalpresses = totalpresses + parseInt(press);
      }
      return totalpresses;
    },
    sessionPressed(value, index) {
      this.sessionSelected = index;
    },
    needsborder(index) {
      if (index === this.sessionSelected) {
        return true;
      } else {
        true;
      }
    },
    sessionItemsToShow() {
      if (this.sessionItems.length > this.sessionrowgrid) {
        const lower = this.gridindex * this.sessionrowgrid;
        const upper = (this.gridindex + 1) * this.sessionrowgrid;
        return this.sessionItems.slice(lower, upper);
      } else {
        return this.sessionItems.slice(0, this.sessionrowgrid);
      }
    },
    forwardButtonPressed() {
      const upper = (this.gridindex + 1) * this.sessionrowgrid;
      if (this.sessionItems.length > upper) {
        this.gridindex = this.gridindex + 1;
        this.sessionSelected = 0;
      }
    },
    previousButtonPressed() {
      const lower = this.gridindex * this.sessionrowgrid;
      if (this.gridindex != 0) {
        if (this.sessionItems.length > lower) {
          this.gridindex = this.gridindex - 1;
          this.sessionSelected = 0;
        }
      }
    },
    isforwardAvailable() {
      const upper = this.gridindex * this.sessionrowgrid + this.sessionrowgrid;
      if (this.sessionItems.length > upper) {
        return true;
      } else {
        return false;
      }
    },
    ispreviousAvailable() {
      if (this.gridindex != 0) {
        return true;
      } else {
        return false;
      }
    },
    gettooltipmessage(value) {
      const val = metrichelp[value];
      if (val == "") {
        return value;
      }
      return val;
    },
    dropdownclicked() {
      this.isdropdownexpanded = !this.isdropdownexpanded;
    },
    dropdownValues(value) {
      const val = this.getMetricValue(value, false);
      return val.split(/\s*[\s,]\s*/);
    },
    isMetricShowCosmoid(value) {
      if (value === "avgResponseTime") {
        return true;
      } else {
        return false;
      }
    },
    getAverageOfArray(value) {
      const values = value.split(/\s*[\s,]\s*/);
      let sum = values.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
      let avg = sum / values.length || 0;
      return naiveRound(avg,1) + " sec"
    },
  },
};
function naiveRound(num, decimalPlaces = 0) {
  var p = Math.pow(10, decimalPlaces);
  return Math.round(num * p) / p;
}
</script>

<style>
.sessionmetric {
  min-height: 100%;
  /* box-shadow: 0px 0px 0px 1px #ccc; */
}
.chooseclass {
  color: #a11e71;
  text-align: center;
  min-height: 100%;
  min-width: 100%;
}
.thumbnailclass {
  width: 25px;
  height: 25px;
  margin-top: 4px;
  margin-left: 5px;
}
.sessioncontainerclass {
  height: 50px;
}
.iconsessionclass {
  height: 28px;
  width: 28px;
}
.infoiconclass {
  height: 12px;
  width: 12px;
}
.shadowclass {
  box-shadow: 6px 6px 12px rgba(213, 68, 150, 0.15);
}
</style>

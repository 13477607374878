<template>
  <div id="dashboard-app">
    <!-- <Initial></Initial> -->
    <vue3-confirm-dialog></vue3-confirm-dialog>
    <NavBar></NavBar>
    <router-view />
  </div>
</template>

<script>
import NavBar from "@/components/Navigation/NavBar";
import Initial from "@/components/Authentication/Initial.vue";

export default {
  name: "App",

  components: {
    Initial,
    NavBar,
  },

  data: () => ({
    //
  }),
};
</script>
